<template>
<div>
  <v-btn block :color="color!=null?color:'teal'" 
         :rounded="rounded!=null?rounded:true"
         :outlined="outlined!=null?outlined:true"
         @click="showPopup = true"
         title="Recevoir de la monnaie"
         :disabled="!$store.state.auth.user.isActive || !$store.getters['auth/accountReady']">
         Recevoir
  </v-btn>
  
  <v-dialog v-model="showPopup" overlay-opacity="1"
            max-width="500" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered hideoverlay">
    <v-card id="dialog-send-mony" v-if="showPopup">
      <v-col cols="12" class="px-5">
        <div style="width:100%;font-size:24px;" class="text-center mt-4">
          Recevoir de la monnaie
        </div>

        <v-divider class="my-4"></v-divider> 

        <template v-if="!useQRCode">
          <v-list rounded two-line>

            <v-list-item rounded class="my-2" @click="useQRCode=true" style="background: #2c2c2c">
              <v-list-item-avatar><v-icon class="mr-2">mdi-qrcode</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Paiement par QR-Code</v-list-item-title>
                <v-list-item-subtitle>Recevoir un paiement par QR-Code</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

          </v-list>

          <b>Autre méthode :</b><br>
          Donnez le numéro du compte sur lequel vous souhaitez recevoir de la monnaie, à la personne qui souhaite vous en envoyer : 
          
          <br><br>
          
          <v-icon small class="m">mdi-chevron-down</v-icon> Liste de vos comptes :

          <v-row class="mt-0" v-if="walletFocus.ownerCompany == null">
            <v-col cols="12" md="12" class="">
              <WalletCard :wallet="walletFocus"></WalletCard>
            </v-col>
          </v-row>
          <v-row class="mt-0" v-else>
            <v-col cols="12" md="12" class="">
              <WalletCard :wallet="walletFocus"></WalletCard>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <PayTerminal :wallet="walletFocus" :closeDialog="closeDialog"/>
        </template>

        <!-- <v-divider class="my-4"></v-divider> -->

        <v-card-actions class="pt-4 px-0">
          <v-spacer></v-spacer>
          <v-btn block elevation="0" class="px-5"  @click="showPopup = false">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </v-dialog>
</div>
</template>


<script>

//import axios from 'axios'
//import router from '../router/router'
import WalletCard from '@/components/wallet/WalletCard.vue'
import PayTerminal from '@/components/wallet/PayTerminal.vue'

export default {
  name: 'auth',
  components: { WalletCard, PayTerminal },
  props: ['walletFocus', 'rounded', 'color', 'outlined'],
  data: () => ({
    showPopup: false,
    useQRCode: false
  }),
  async mounted(){
    this.$root.$off('closePopupReceiveMony').$on('closePopupReceiveMony', () => { 
        console.log("closePopupReceiveMony")
        this.showPopup = false
    })
  },
  methods: {

    closeDialog(){
      this.showPopup = false
    }
  }
}
</script>
