<template>
  <div v-resize="qrCodeSize">
    <v-row class="mb-4 mx-0">
      <v-col cols="12" class="px-0 text-center">
        <v-icon>mdi-chevron-down</v-icon> Saisissez le montant de la transaction
        <!-- <br>
        • Validez pour générer le QR-code -->
      </v-col>

      <v-col cols="11" class="px-0 mx-auto">
        <div class="px-4 py-2 pb-1 chip-amount  primary--text">
          <span class="font-led">{{ lblAmountToSend }} <small>PRT</small></span>
          <small class="lbl-euro white--text" v-if="lblAmountToSend!='...'">~{{ (parseFloat(lblAmountToSend)*100).toFixed(2) }}€</small>
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-4 mx-0" v-if="!validated">
      <template v-for="i in [1,2,3,4,5,6,7,8,9]">
        <v-col cols="4" :key="i" class="pa-1" @click="addNumToAmount(i)">
          <v-btn block outlined color="primary" class="btn-keyboard">{{ i }}</v-btn>
        </v-col>
      </template>

      <v-col cols="4" class="pa-1" @click="addNumToAmount(0)">
        <v-btn block outlined color="primary" class="btn-keyboard">0</v-btn>
      </v-col>
      <v-col cols="4" class="pa-1">
        <v-btn block outlined color="grey" :disabled="amountToSend == ''" @click="addNumToAmount('.')" class="btn-keyboard">.</v-btn>
      </v-col>
      <v-col cols="4" class="pa-1">
        <v-btn block outlined color="grey" @click="removeNumToAmount()" class="btn-keyboard">
          <v-icon>mdi-backspace-outline</v-icon>
        </v-btn>
      </v-col>
      <v-col class="px-1 mt-5 text-center">
        <v-btn block color="green" class="mb-2" @click="validateAmount">Valider</v-btn>
        <small>Validez pour générer le QR-code</small>
      </v-col>
    </v-row>
    <v-row v-else>
      <div class="d-inline-block" id="qrcode">
        <!-- {{ qrData }} -->
        <qr-code :text="qrData" :size="codeSize"></qr-code>
      </div>
      <v-col cols="12" class="text-center">
        <small>Scannez le QR-Code pour effectuer le paiement</small>
        <v-btn block class="mt-3" @click="validated=false" title="modifier le montant">Corriger</v-btn>
        <v-btn block class="mt-3" @click="closeDialog()" title="modifier le montant" color="green">
          Continuer <v-icon small class="ml-1">mdi-arrow-right-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
  </template>

  <style>
  
  #qrcode img{
    /* border: 8px solid white !important; */
  }
  #qrcode{
    margin-left:auto;
    margin-right:auto;
    border: 8px solid white !important;
  }
</style>
  
  <script>
  
  //import axios from 'axios'
  //import router from '../router/router'
  
  import VueQRCodeComponent from 'vue-qrcode-component'

  export default {
    name: 'auth',
    components: { 'qr-code' : VueQRCodeComponent },
    props: ['wallet', 'closeDialog'],
    data: () => ({
      amountToSend: "",
      qrData: "",
      validated: false,
      codeSize: 0
    }),
    async mounted(){
      this.qrCodeSize()
    },
    methods: {
  
      validateAmount(){
        this.validated = true 
        let data = { amount: this.amountToSend, walletUid: this.wallet.uid, walletType: this.wallet.type, 
                     ownerName: (this.wallet.ownerCompany != null ?  this.wallet.ownerCompany.name :  this.$store.state.auth.user.name) }
        this.qrData = JSON.stringify(data)
      },


      addNumToAmount(num){
        if(num == "." && this.amountToSend.indexOf(num) >= 0) return
        this.amountToSend = this.amountToSend + "" + num
      },
      removeNumToAmount(){
        this.amountToSend = this.amountToSend.substr(0, this.amountToSend.length-1)
      },
      qrCodeSize(){ this.codeSize = document.getElementById('dialog-send-mony').offsetWidth - 100 },
    },
    computed: {
      lblAmountToSend(){
        let amount = this.amountToSend
        if(amount == "") amount = "..."
        
        return amount
      }
    }
  }
  </script>
  