<template>
  <div >
        <v-btn elevation="0" block class="my-3" @click="switchCameraFormat()" title="">
          <v-icon small class="mr-1">mdi-overscan</v-icon> améliorer la résolution
        </v-btn>
        <v-btn elevation="0" block class="my-3" @click="closeQrReader()" title="modifier le montant">
          <v-icon small class="mr-1">mdi-arrow-left-circle</v-icon> Retour
        </v-btn>
      <vue-qr-reader v-if="cameraHeight > 0 && qrData == null" 
        id="qrcode-container" 
        v-on:code-scanned="codeArrived"
        line-color="#fbc02d"
        :use-back-camera="true"
        :line-width="4" 
        :video-width="cameraWidth" 
        :video-height="cameraHeight"
      />
      <v-col cols="12" class="text-center">
        <small>Scannez le QR-Code pour effectuer le paiement</small>
        <!-- <v-btn block class="mt-3" title="modifier le montant" color="green">
          Continuer <v-icon small class="ml-1">mdi-arrow-right-circle</v-icon>
        </v-btn> -->

        <!-- {{ stream_width }} {{ stream_height }} {{ ratio }} {{ cameraWidth }} {{ cameraHeight }}  -->
      </v-col>
  </div>
</template>

<script>

import axios from 'axios'
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js'

export default {
  components: {
      VueQrReader
  },
  props:['closeQrReader', 'walletFocus', 'refreshWalletData'],
  data: () => ({
    cameraWidth: 0,
    cameraHeight: 0,
    qrData: null,

    stream_width: 0,
    stream_height: 0,
    ratio: 0
  }),
  mounted(){
    this.qrCameraSize()
  },
  methods: {
      codeArrived (qrData) {
          console.log("codeArrived", qrData)
          if(qrData != null){
            this.qrData = JSON.parse(qrData)
            this.sendMoney()
            //todo : axion.get("")
          }
      },
      async sendMoney(){
        let params = {
          amount: parseFloat(this.qrData.amount),
          fromWalletUid: this.walletFocus.uid,
          fromWalletType: this.walletFocus.type,
          toWalletUid: this.qrData.walletUid,
          toWalletType: this.qrData.walletType,
          libelle: ""
        }
        console.log("params", this.qrData.amount, params)
        let res = await axios.post("/oto/send-mony", params)
        if(res.data.error == false){
          this.refreshWalletData()
        }
      },
      async qrCameraSize(){
        this.cameraWidth = document.getElementById('dialog-send-mony').offsetWidth - 40
      
        let constraints = {  audio: false, video: { facingMode: { ideal: "environment" }, }, }

        let stream = await navigator.mediaDevices.getUserMedia(constraints)
        let stream_settings = stream.getVideoTracks()[0].getSettings()

        //alert("stream_settings: " + JSON.stringify(stream_settings))

        // actual width & height of the camera video
        this.stream_width = stream_settings.width
        this.stream_height = stream_settings.height

        this.ratio = this.stream_height / this.stream_width

        this.cameraHeight =  this.cameraWidth * this.ratio 

        // console.log('Width: ' + this.stream_width + 'px');
        // console.log('Height: ' + this.stream_height + 'px');
      },
      switchCameraFormat(){
        let width = this.stream_width
        this.stream_width = this.stream_height
        this.stream_height = width

        this.ratio = this.stream_height / this.stream_width
        this.cameraHeight =  this.cameraWidth * this.ratio 
      }
  },
  computed:{
  }
}
</script>