<template>
<div>
  <v-btn block class="" 
         :color="color!=null?color:'primary'" 
         :rounded="rounded!=null?rounded:true"
         :outlined="outlined!=null?outlined:true"
         @click="showPopup = true"
         title="Envoyer de la monnaie vers un autre compte"
         :disabled="!$store.state.auth.user.isActive || !$store.getters['auth/accountReady']">
         Envoyer
  </v-btn>

  <v-dialog v-model="showPopup" transition="dialog-bottom-transition" :overflowed="true"
            max-width="600" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered" overlay-opacity="1">
    <v-card id="dialog-send-mony" v-if="showPopup">
      <v-col cols="12" class="px-5 py-0 pt-4">
        <div style="width:100%;font-size:20px; line-height:23px;" class="text-center mb-3">
          Envoyer de la monnaie<br>
          <small v-if="walletFocus.type=='MAIN'">depuis votre Compte Courant : <b>{{ walletFocus.uid }}</b></small>
          <small v-if="walletFocus.type=='DEPOSIT'">depuis votre Compte de Dépôt : <b>{{ walletFocus.uid }}</b></small>
        </div>

        <v-divider class="my-0" v-if="!toWalletExistState"></v-divider> 
        
        <v-list v-if="!toWalletExistState && !useQRCode" rounded two-line>

          <v-list-item rounded class="my-2" @click="useQRCode=true" >
            <v-list-item-avatar><v-icon class="mr-2">mdi-qrcode</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Paiement par QR-Code</v-list-item-title>
              <v-list-item-subtitle>Réaliser un achat en scannant un QR-Code</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item rounded class="my-2" @click="selectWallet('DEPOSIT')" 
                        v-if="walletFocus.type=='MAIN' || walletFocus.ownerCompany != null"
                        :disabled="$store.state.auth.user.walletsDeposit.length == 0">
            <v-list-item-avatar><v-icon class="mr-2">mdi-wallet</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Vers votre Compte de Dépôt</v-list-item-title>
              <v-list-item-subtitle>Transférez votre monnaie vers votre Compte de Dépôts</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item rounded class="my-2" @click="selectWallet('MAIN')" v-else-if="walletFocus.type=='DEPOSIT'"
                        :disabled="$store.state.auth.user.walletsDeposit.length == 0">
            <v-list-item-avatar><v-icon class="mr-2">mdi-wallet</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Vers votre Compte Courant</v-list-item-title>
              <v-list-item-subtitle>Transférez votre monnaie vers votre Compte Courant</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item rounded class="my-2" @click="openPopupContact" 
                        :disabled="false">
            <v-list-item-avatar><v-icon class="mr-2">mdi-badge-account-horizontal-outline</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Vers un contact</v-list-item-title>
              <v-list-item-subtitle>Transférez votre monnaie vers le compte de l'un de vos contacts</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item rounded class="my-2"
                        :disabled="false">
            <v-list-item-avatar><v-icon class="mr-2">mdi-plus-circle</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Vers un nouveau compte</v-list-item-title>
              <v-list-item-subtitle>
                <v-icon small>mdi-chevron-down</v-icon> 
                Transférez votre monnaie vers un nouveau compte tiers
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-list v-if="toWalletExistState!= true && typeWallet == 'deposit'" rounded two-line class="mb-2 px-5">
          <template v-for="(wallet, w) in $store.state.auth.user.walletsDeposit">
            <v-list-item rounded class="my-2" @click="typeWallet='deposit'" :key="w">
              <v-list-item-avatar><v-icon class="mr-2">mdi-wallet</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ wallet.name != "DEPOSIT" ? wallet.name : 'Compte de Dépôt' }} - {{ wallet.uid }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle>Transférez votre monnaie vers l'un de vos Compte de Dépôts</v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>       

        <v-text-field :color="toWalletExist != false ? 'green' : 'primary'" 
                      outlined rounded class="text-center toWalletUID"
                      v-if="!useQRCode && toWalletExistState == false"
                      :rules="[() => toWalletUID == null || toWalletUID.length <= 6 || 'Max 6 caractères']"
                      label="n° du compte à créditer" 
                      :loading="searchingWallet" clearable
                      placeholder="ABCD12" 
                      v-model="toWalletUID"
                      @keyup="checktoWalletUID"
                      @focusout="checktoWalletUID"
                      :hide-details="true">
        </v-text-field>

        <v-toolbar outline v-if="toWalletExistState != false" dense elevation="0" color="" class="check-wallet-bar primary--text">
          <v-icon small color="primary" class="mr-1">
            mdi-<template v-if="walletFocus.type=='MAIN'">credit-card-clock</template>
                <template v-else>credit-card-settings</template>
          </v-icon>
          <small class="check-wallet-uid">{{ walletFocus.uid }}</small>
          <v-icon small color="primary" class="ml-0">mdi-chevron-left</v-icon>
          <b class="px-1">{{ walletFocus.ownerCompany != null ? walletFocus.ownerCompany.name : $store.state.auth.user.name }}</b> 
          <v-spacer></v-spacer>
          <v-icon small color="primary" class="d-none d-sm-inline">mdi-check</v-icon>
          <v-gravatar height="26" class="ml-1 my-1" style="border-radius:50%;"
                      :default-img="'retro'" :email="$store.state.auth.user.name"/>
        </v-toolbar>

        <v-toolbar outline v-if="toWalletExistState != false" dense elevation="0" color="" class="check-wallet-bar green--text mt-1">
          <v-icon small color="green" class="mr-1">
            mdi-<template v-if="toWalletExist.type=='MAIN'">credit-card-clock</template>
                <template v-else>credit-card-settings</template>
          </v-icon>
          <small class="check-wallet-uid">{{ toWalletExist.uid }} </small>
          <v-icon small color="green" class="ml-0">mdi-chevron-right</v-icon>
          <b class="px-1"> {{ toWalletExist.ownerCompany != null ? toWalletExist.ownerCompany.name : toWalletExist.owner.name }}</b> 
          <v-spacer></v-spacer>
          <v-icon small color="green" class="d-none d-sm-inline">mdi-check</v-icon>
          <v-gravatar height="26" class="ml-1 my-1" style="border-radius:50%;"
                      :default-img="'retro'" :email="toWalletExist.owner.name"/>
        </v-toolbar>
        
        <v-alert outlined v-if="toWalletUID == walletFocus.uid" 
                dense type="error" class="mt-4">
          Impossible d'envoyer de la monnaie sur le même compte !
        </v-alert>
        <v-alert outlined v-else-if="!searchingWallet && toWalletExistState == false && toWalletUID != null && toWalletUID.length == 6" 
                dense type="error" class="mt-4">
          {{ toWalletUID.toUpperCase() }} Ce compte n'existe pas
        </v-alert>
              
        
        <template v-if="toWalletExistState == true">
          <!-- <v-divider class="my-4"></v-divider> -->
          
          <!-- <div class="pb-4 py-2 pt-3 text-center --text">
            Vous disposez <span class="d-none d-sm-inline">actuellement</span> de 
            <b>{{ amountCurrent.toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
          </div> -->

          <v-row class="mb-4 mt-2 mx-0">

            <v-col cols="12" class="px-1 mx-auto">
              <div class="px-4 py-0 chip-amount" :class="amountToSend > amountCurrent ? 'price-error' : 'primary--text'">
                <span class="font-led">{{ lblAmountToSend }} <small>PRT</small></span>
                <small class="lbl-euro white--text" v-if="lblAmountToSend!='...'">
                  ~{{ (parseFloat(lblAmountToSend)*100).toFixed(2) }}€
                </small>
              </div>
            </v-col>

            <v-col cols="12" class="pa-0 px-1">
              <v-alert :color="amountCorrect ? 'grey' : 'red'" dark outlined dense class="mb-2">
                <template v-if="amountCorrect">Montant maximum autorisé : </template>
                <template v-if="!amountCorrect">Montant maximum autorisé : </template>
                <b>{{ amountCurrent.toFixed(4) }} {{ $store.state.app.monyUnity }}</b>
              </v-alert>
            </v-col>
            
            <template v-for="i in [1,2,3,4,5,6,7,8,9]">
              <v-col cols="4" :key="i" class="pa-1">
                <v-btn block outlined color="primary" 
                  @click="addNumToAmount(i)" class="btn-keyboard">
                  {{ i }}
                </v-btn>
              </v-col>
            </template>

            <v-col cols="4" class="pa-1" :disabled="amountToSend == ''" @click="addNumToAmount(0)">
              <v-btn block outlined color="primary" class="btn-keyboard">0</v-btn>
            </v-col>
            <v-col cols="4" class="pa-1">
              <v-btn block outlined color="grey" @click="addNumToAmount('.')" class="btn-keyboard">.</v-btn>
            </v-col>
            <v-col cols="4" class="pa-1">
              <v-btn block outlined color="grey" @click="removeNumToAmount()" class="btn-keyboard">
                <v-icon>mdi-backspace-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="7" sm="9" class="py-0 pr-1">
              <v-text-field outlined v-model="libelle" clearable dense hide-details class="mb-3"
                            placeholder="Description courte de la transaction" 
                            label="Libellé (facultatif)">
              </v-text-field>
            </v-col>

            <v-col cols="5" sm="3" class="py-0 pl-1">
              <v-btn height="39" color="green" block elevation="0" dark @click="sendMoney"
                    :disabled="parseFloat(amountToSend) > parseFloat(amountCurrent.toFixed(4)) || amountToSend == '' || amountToSend == null">
                    <v-icon>mdi-check</v-icon>
                <span class="d-none d-sm-inline">Envoyer</span> 
              </v-btn>
            </v-col>
          </v-row>

          <v-col cols="12" v-if="amountToSend > amountCurrent" class="px-0 mx-auto text-center red--text">
            montant trop élevé !
          </v-col>
        </template>

        <template v-if="useQRCode">
          <QrcodeReader :closeQrReader="closeQrReader" :walletFocus="walletFocus" :refreshWalletData="refreshWalletData"/>
        </template>

        <!-- <v-divider class="mt-3 mb-1"></v-divider> -->

        <v-card-actions class="px-0 mt-2">
          <v-btn elevation="0" block color="px-5 mb-2"  @click="cancelSending" >
             Annuler
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </v-dialog>


  <v-dialog v-model="showPopupContact" transition="dialog-top-transition"
            max-width="600" :fullscreen="$vuetify.breakpoint.width < 600"
            content-class="dialog-large-bordered">
    <v-card id="dialog-send-mony">
      <v-col cols="12" class="px-5">
        <div style="width:100%;font-size:20px;" class="text-center mt-4">
          Sélectionnez parmis vos contacts
        </div>

        <v-divider class="my-4"></v-divider> 

        <v-list v-if="$store.state.auth.user.walletsContact.length > 0" rounded>
          <template v-for="(contact, k) in $store.state.auth.user.walletsContact">
            <v-list-item rounded class="" @click="selectContact(contact)" :key="k">
              <v-list-item-avatar><v-icon class="mr-2">mdi-wallet</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title><b>{{ contact.ownerName }}</b><small class="ml-2">{{ contact.uid }}</small></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <template v-else>
          <b>Vous n'avez aucun contact pour l'instant.</b>
          <br><br>
          Lorsque vous envoyez ou recevez de la monnaie, le compte tiers avec lequel vous effectuez la transaction 
          est automatiquement ajouté à votre liste de contacts.
        </template>

        <v-divider class="my-4"></v-divider> 

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn class="px-5" elevation="0" @click="cancelSelectContact" >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-card>
  </v-dialog>
</div>
</template>

<style>
  .toWalletUID input{
    font-size: 31px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
  }
  #dialog-send-mony .v-list-item__subtitle{
    white-space: initial !important;
  }
  .btn-keyboard{
    height:36px !important;
    font-size: 23px !important;
  }

  .check-wallet-bar{
    height: 39px !important;
    border-radius:5px !important;
  }
  .check-wallet-bar .v-toolbar__content{
    padding-left:8px;
    padding-right:8px;
    height: 39px !important;
    line-height:15px;
  }

  .price-error{
    border-color:red !important;
  }
  .chip-amount.price-error{
    color:red !important;
  }

  @media (max-width: 500px) {
  .btn-keyboard{
    height:30px !important;
    font-size: 20px !important;
  }
}
@media (max-width: 340px) {
  .check-wallet-bar .mdi-check-circle{
    display:none;
  }
}
  
@media (max-width: 300px) {
  .check-wallet-bar .check-wallet-uid{
    display:none;
  }
}
  
</style>

<script>

import axios from 'axios'
import Gravatar from 'vue-gravatar'
import core from "../../plugins/core"

// import { QrcodeStream } from 'vue-qrcode-reader'

import QrcodeReader from '@/components/wallet/QrcodeReader.vue'

//import router from '../router/router'

export default {
  name: 'auth',
  props: ['walletFocus', 'rounded', 'color', 'outlined'],
  components: { 'v-gravatar' : Gravatar, QrcodeReader },
  data: () => ({
    showPopup: false,
    toWalletUID: "",
    searchingWallet: false,
    lastUidSearch: "",
    toWalletExist: false,

    amountToSend: "",
    oldAmount: "",
    libelle: "",
    
    showPopupContact: false,
    typeWallet: "",

    useQRCode: false
  }),
  async mounted(){
    
    this.$root.$off('closePopupSendMony').$on('closePopupSendMony', () => { 
        console.log("closePopupSendMony")
        this.showPopup = false
    })
  },
  methods: {

    codeArrived (event) {
        console.log(event.detail[0]);
    },
    onDetect (detectedCodes) {
        console.log("detectedCodes", detectedCodes);
      // ...
    },
    closeQrReader(){
      this.useQRCode = false
    },

    // keydownAmount(event){
    //   if(event.key.length > 1 || this.amountToSend == null) return

    //   let ascii = event.key.charCodeAt(0)
    //   if((ascii < 48 || ascii > 57) //si ce n'est pas un nombre
    //   && (event.key != "." || this.amountToSend.indexOf(".") > -1) //et que ce n'est pas un point, ni un 2eme point
    //   || this.amountToSend.length >= parseFloat(this.amountToSend).toFixed(4).length
    //   || this.amountToSend.length >= 6) { //ou que le nombre de chiffre apres la virgule est de max 4
    //       event.preventDefault() //annule la frappe au clavier
    //   }
    // },

    async checktoWalletUID(){

      if(this.toWalletUID == null || this.toWalletUID.length != 6) {
        this.searchingWallet = false
        this.toWalletExist = false
        return
      } 

      this.searchingWallet = true

      let res = await axios.get("/oto/wallet-exists/" + this.toWalletUID.toUpperCase())
      if(res.data.error == false){
        this.toWalletExist = res.data.wallet
      }else{
        this.toWalletExist = false
      }
      this.searchingWallet = false
      this.lastUidSearch = this.toWalletUID

      console.log("uid account", this.toWalletUID)
    },

    cancelSending(){
      this.typeWallet = ""
      this.amountToSend = ""
      this.toWalletUID = ""
      this.toWalletExist = false
      this.showPopup = false
      this.libelle = ""
    },

    async sendMoney(){
      let floatAmount = parseFloat(this.amountToSend)
      if(floatAmount == 0 || floatAmount == null) return 

      let params = {
        amount: floatAmount,
        fromWalletUid: this.walletFocus.uid,
        fromWalletType: this.walletFocus.type,
        toWalletUid: this.toWalletExist.uid,
        toWalletType: this.toWalletExist.type,
        libelle: this.libelle == null ? "" : this.libelle
      }
      console.log("sendMoney", params)
      let res = await axios.post("/oto/send-mony", params)
      if(res.data.error == false){
        this.refreshWalletData()
        this.cancelSending()
      }else{
        this.toWalletExist = false
      }
    },

    async refreshWalletData(){
      await this.$store.dispatch('auth/refreshUserData')
      this.$root.$emit('rerenderChartWallet')
    },

    openPopupContact(){
      this.showPopup = false 
      this.showPopupContact = true
    },

    selectContact(contact){
      this.searchingWallet = true
      this.toWalletUID = contact.uid
      this.checktoWalletUID()
      this.showPopupContact = false 
      this.showPopup = true
    },

    addNumToAmount(num){
      console.log("addNumToAmount", num, this.amountToSend, this.amountToSend.indexOf(num))
      if(num == "." && this.amountToSend.indexOf(num) >= 1) return
      if(num == "." && this.amountToSend.length == 0) return
      this.amountToSend = this.amountToSend + "" + num
    },
    removeNumToAmount(){
      this.amountToSend = this.amountToSend.substr(0, this.amountToSend.length-1)
    },
    cancelSelectContact(){
      this.showPopupContact = false 
      this.showPopup = true
    },

    selectWallet(walletType){
      console.log("selectWallet", walletType)
      this.searchingWallet = true
      if(walletType == "MAIN") this.toWalletUID = this.$store.state.auth.user.walletMain.uid
      if(walletType == "DEPOSIT") this.toWalletUID = this.$store.state.auth.user.walletsDeposit[0].uid
      this.checktoWalletUID()

    }

  },
  computed: {
    toWalletExistState(){ 
      if(!this.toWalletExist) return false
      return this.toWalletExist.uid != this.walletFocus.uid
    },
    fromMyWallet(){ return this.transaction.fromWallet.id == this.$store.state.auth.user.walletMain._id },
    amountCurrent() { 
      if(this.walletFocus == null) return 0 

      if(this.walletFocus.type == "MAIN"){
        console.log("this.walletFocus.transactions", this.walletFocus.transactions)
        let stackAmount = 0
        let transs = JSON.parse(JSON.stringify(this.walletFocus.transactions))
        transs.forEach((trans) => {
          //si le wallet qui reçoit est mon compte : ajoute le montant
          if(trans.toWallet == null || trans.toWallet.id == this.walletFocus._id)
              stackAmount += trans.amountUnity
          else stackAmount -= trans.amountUnity //sinon : soustrait le montant
        })
        return core.convertUnityMony(stackAmount, this.$store.state.auth.monyConvertValue)
      }
      else if(this.walletFocus.type == "DEPOSIT"){
        return this.walletFocus.amountMony
      }
      return 0
    },
    lblAmountToSend(){
      let amount = this.amountToSend
      if(amount == ""
      || amount == ".") amount = "..."
      
      return amount
    },
    amountCorrect(){
      return (this.amountToSend < this.amountCurrent)
    },
  },

}
</script>
